<template>
  <div>
    <el-container>
      <el-aside class="home-aside" width="200px" v-if="$route.path !== '/home/payment'">
        <el-row class="tac">
          <el-col>
            <el-menu :router="true" default-active="0" @select="handleMenuSelect">
              <el-menu-item v-for="(item, index) in menus" :index="'' + (index + 1)" :key="index" :route="item.route"
                style="padding-left: 0px;">
                <template slot="title">
                  <div :class="item.selected ? 'menu-item-selected menu-item' : ' menu-item'">
                    <span>{{ item.title }}</span>
                  </div>
                </template>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </el-aside>
      <el-main class="home-content">
        <div style="flex: 1; overflow-y: auto">
          <keep-alive include="record">
            <router-view></router-view>
          </keep-alive>
        </div>
        <div style="
            width: 100%;
            height: 50px;
            text-align: center;
            line-height: 50px;
            color: #cccccc;
            font-size: 16px;
            font-weight: 400;
          ">
          {{ currenrYear }} WSPR | All Rights Reserved
        </div>
      </el-main>
    </el-container>
  </div>
</template>


<script>
export default {
  name: "Home",
  data() {
    return {
      currenrYear: '',
      currentIndex: 0,
      menus: [
        {
          selected: true,
          title: "Applications",
          route: "/home/applications",
          // selectedIcon: require("../assets/image/icon_registration_select.png"),
          // unselectIcon: require("../assets/image/icon_registration_unselect.png"),
        },
        // {
        //   selected: false,
        //   title: "Feedback & Inquiry",
        //   route: "/home/feedback",
        //   // selectedIcon: require("../assets/image/icon_review_entries_select.png"),
        //   // unselectIcon: require("../assets/image/icon_review_entries_unselect.png"),
        // },
        {
          selected: false,
          title: "Account Info",
          route: "/home/accountInfo",
          // selectedIcon: require("../assets/image/icon_review_entries_select.png"),
          // unselectIcon: require("../assets/image/icon_review_entries_unselect.png"),
        }
      ],
    };
  },
  methods: {
    handleMenuSelect(index) {
      if (this.currentIndex == index) {
        return;
      }
      for (let i = 0; i < this.menus.length; i++) {
        this.menus[i].selected = false;
      }
      this.currentIndex = index;
      this.menus[index - 1].selected = true;
    },
  },
};
</script>

<style scoped lang="less">
.home-content {
  height: calc(100vh - 72px);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.home-aside {
  overflow-y: auto;
  height: calc(100vh - 72px);
  background: #ffffff;
  box-shadow: 2px 0px 6px 1px rgba(0, 21, 41, 0.12);

  /deep/ .el-menu {
    height: calc(100vh - 72px);
    border-right: none;
  }
}

/deep/ .el-menu-item {
  padding: 0px 0px;
}

.menu-item {
  box-sizing: border-box;
  padding-left: 20px;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  color: var(--c-primary);
  font-size: 15px;
  font-family: GravityBold;
  background-color: #fff;
  border-top: 1px solid white;

  &:hover {
    color: white;
    background-color: var(--c-primary);
  }
}

.menu-item-selected {
  color: white;
  background-color: var(--c-primary);
}
</style>
